import { Component, OnInit } from '@angular/core';
import {News} from "../../_models/news";
import {CommonService} from "../../_services/common.service";
import {Title} from "@angular/platform-browser";
import {NgDynamicBreadcrumbService} from "ng-dynamic-breadcrumb";
import { EvenementService } from 'src/app/_services/evenement.service';
import { TagService } from 'src/app/_services/tag.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl } from '@angular/forms';
import {NgbDateStruct, NgbModal, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { PropositionFormComponent } from 'src/app/proposition-form/proposition-form.component';

@Component({
  selector: 'app-agenda-describe',
  templateUrl: './agenda-describe.component.html',
  styleUrls: ['./agenda-describe.component.scss']
})
export class AgendaDescribeComponent implements OnInit {
  from: NgbDateStruct | null = null;
  to: NgbDateStruct | null = null;
  event: any;
  eventForm: any;
  tags: any;
  title: any;
  constructor(private commonService: CommonService, private titleService: Title, private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService,
              private eventService: EvenementService, private tagService: TagService,
              private route: ActivatedRoute, private formBuilder: FormBuilder, private modalService: NgbModal, private router: Router, private ngbDateParser: NgbDateParserFormatter) { }

  ngOnInit(): void {
    this.title = this.route.snapshot.paramMap.get('slug')
    this.getTags();
    this.getEvent();
    this.eventForm = this.formBuilder.group({
      categorie: new FormControl(''),
      dateDebut: new FormControl(''),
      dateFin: new FormControl(''),
    });
  }

  getTags() {
    this.tagService.getTags().subscribe(data => {
      this.tags = data;
    });
  }

  getEvent() {
    this.eventService.getOneEvent(this.title).subscribe(data => {
      this.event = data;
      this.commonService.changeData(data.texte_vignette);
      this.titleService.setTitle(data.titre);
      this.ngDynamicBreadcrumbService.updateBreadcrumbLabels({customMenu: 'Mes Loisirs', customSection:'Évènements', customTitle: data.titre});
    });
  }

  openPopUpPage(type: string): void{
    const modalCreate = this.modalService.open(PropositionFormComponent, {size: 'xl', centered:true});
    modalCreate.componentInstance.type = type;
  }

  onSubmit(): void {
    let info = {
      'categorie': this.eventForm.get('categorie').value,
      'dateDebut': this.eventForm.get('dateDebut').value,
      'dateFin': this.eventForm.get('dateFin').value,
    }
    this.router.navigate(['mes-loisirs/evenements'], {queryParams: {'categorie': info.categorie, 'jourDebut': info.dateDebut.day, 'moisDebut': info.dateDebut.month, 'anneeDebut': info.dateDebut.year, 'jourFin': info.dateFin.day, 'moisFin': info.dateFin.month, 'anneeFin': info.dateFin.year}});
  }
}
