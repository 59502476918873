<div class="blue-overlay"></div>
<section id="intro">
  <div class="container text-center text-lg-start">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <ngx-slick-carousel class="carousel intro-carrousel" #slickModal="slick-carousel" [config]="slideConfig">
          <div ngxSlickItem *ngFor="let info of infos" class="slide">
            <h2>{{info.titre }}</h2>
            <p class="py-3">{{ makeStringShorter(info.description)}}</p>
            <a *ngIf="info.url" href="{{info.url}}"> En savoir plus </a>
          </div>
        </ngx-slick-carousel>
        <div class="row pt-3">
          <div class="col-lg-2 col-6 intro-options my-lg-0 my-2 text-center"  *ngFor="let option of introsOptions">
              <a href="{{option.link}}" target="{{option.target}}">
                <img  class="img-fluid" src="{{option.image}}">
                <p><strong>{{ option.name }}</strong></p>
              </a>
          </div>
        </div>
      </div>
      <div class="position-relative col-lg-6 mt-lg-0 mt-5">
        <div class="arrow-slick-caroussel arrow-left-caroussel col-2 prev-event-caroussel" >
          <i class="fa-solid fa-chevron-left"></i>
        </div>

        <ngx-slick-carousel class="carousel intro-carrousel" #slickModal="slick-carousel" [config]="{ autoplay: true, autoplaySpeed: 3000, prevArrow: '.prev-event-caroussel',  nextArrow: '.next-event-caroussel', draggable: true, dots: false, infinite: true, arrows: true}">
          <div ngxSlickItem *ngFor="let slide of sliders" class="slide">
            <img  class="img-fluid" src="{{slide.urlImage}}">
          </div>
        </ngx-slick-carousel>

        <div class="arrow-slick-caroussel arrow-right-caroussel col-2 next-event-caroussel" >
          <i class="fa-solid fa-chevron-right"></i>
        </div>
      </div>
    </div>
    <img class="intro-points" src="assets/icons/element-graphique-point.svg">
  </div>
</section>

<section id="calendar">
  <div class="container-fluid">
    <div class="float-end titre text-center text-lg-end">
        <h3 class="text-white d-inline-block">L' <span>AGENDA</span></h3>
        <p class="text-white">Les événements à venir sur la<br/> commune de Brécé</p>
    </div>
    <div class="clearfix"></div>
    <div class="container">
      <div class="row event-content">
        <div class="col-12 col-md text-center my-lg-0 my-3" *ngFor="let event of events?.slice(0,4)">
          <div class="event">
            <p>{{ event.dateDebut.date |date:'LLLL'}}</p>
            <p>{{ event.dateDebut.date |date:'dd'}}</p>
            <p>{{ event.titre}}</p>
            <a *ngIf="!event.intra" class="link-event" [routerLink]="['mes-loisirs','evenements', event.slug]"></a>
            <a *ngIf="event.intra" class="link-event" [routerLink]="['mes-loisirs','evenements',event.id, event.slug]" [state]="{id: event.id}"></a>
          </div>
        </div>
        <div class="col-12 col-lg-2 align-self-end text-center text-lg-start">
          <a class="btn btn-dark" href="/mes-loisirs/evenements">TOUTES LES DATES</a>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="news" class="section-margin">
  <div class="container" *ngFor="let actu of actualites?.slice(0,1)">
    <a class="row main" href="/actualites/{{actu.slug}}">
      <div class="col-lg-8">
          <h2 class="mb-5">en ce <span>MOMENT</span></h2>
          <div class="image">
            <div class="point"></div>
            <img class="img-fluid image-main" src="{{ actu.urlImage }}">
          </div>
      </div>
      <div class="col-lg-4">
        <div class="mb-5 float-end points-bleu">
          <img class="img-fluid" src="assets/icons/element-graphique-point-bleu.svg">
        </div>
        <div class="clearfix"></div>
        <h3 class="my-3">{{actu.titre}}</h3>
        <p class="text-black">{{actu.textVignette}}</p>
          <a class="btn-news" href="/actualites/{{actu.slug}}">
            <span class="fa fa-arrow-right"></span>
          </a>
      </div>
    </a>
    <div class="row mt-5">
      <a class="col-lg-4 my-lg-0 my-3 text-lg-start text-center"  *ngFor="let actu of actualites?.slice(1,4)" >
        <a *ngIf="!actu.intra" class="link-actu" [routerLink]="['actualites',actu.slug]">
          <img class="img-fluid img-actu" src="{{actu.urlImage}}">
          <h3 class="my-4 title-actu" >
            {{ makeStringShorter(actu.titre) }}
          </h3>
          <p>
            {{actu.texte_vignette}}
          </p>
          <a class="link-actu" href="/actualites/{{actu.slug}}">
            <button class="btn-news">
              <span class="fa fa-arrow-right"></span>
            </button>
          </a>
        </a>

        <a *ngIf="actu.intra" class="link-actu" [routerLink]="['actualites', actu.id ,actu.slug]" [state]="{id: actu.id}">
          <img class="img-fluid img-actu" src="{{actu.urlImage}}">
          <h3 class="my-4 title-actu" >
            {{ makeStringShorter(actu.titre) }}
          </h3>
          <p>
            {{actu.texte_vignette}}
          </p>
          <a class="link-actu" href="/actualites/{{actu.id}}/{{actu.slug}}">
            <button class="btn-news">
              <span class="fa fa-arrow-right"></span>
            </button>
          </a>
        </a>
      </a>
    </div>
    <div class="text-center btn-all">
      <a class="btn btn-dark" href="/actualites">
        Toutes les actualités
      </a>
    </div>
  </div>
</section>
<section class="blue-section">
  <div class="container">
    <img class="img-fluid" src="assets/icons/element-graphique-point.svg">
  </div>
</section>

<section id="newsletter">
    <div class="container">
      <div class="row-bootstrap">

        <div class="col-lg-4 col-12">
          <div class="newsletter">
            <h4>Le <span>BIB</span></h4>

            <div class="row align-items-center position-relative">
              <div class="arrow-slick arrow-left col-2 prev-event" >
                <i class="fa-solid fa-chevron-left"></i>
              </div>

              <ngx-slick-carousel class="carousel intro-carrousel col-8"  [config]="{  prevArrow: '.prev-event',  nextArrow: '.next-event', draggable: true, dots: false, infinite: true, arrows: true}">
                <div ngxSlickItem *ngFor="let bib of bibs" class="slide text-center">
                  <div class="bib-container">
                    <p class="my-3"><small>{{bib.nom}}</small></p>
                    <img class="img-fluid img-carousel" src="{{bib.vignette}}">
                    <button class="btn btn-dark my-4"> <a href="{{bib.fichier}}" target="_blank"> TÉLÉCHARGER LE BIB</a></button>
                  </div>
                </div>
              </ngx-slick-carousel>

              <div class="arrow-slick arrow-right col-2 next-event" >
                <i class="fa-solid fa-chevron-right"></i>
              </div>
            </div>

            <div class="text-center">
              <a href="/ma-commune/publications/bib">consulter les numéros précédents</a>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-12 my-3 my-lg-0 ">
          <div class="newsletter">
            <h4>Le <span>FLASH INFO</span></h4>

            <div class="row align-items-center position-relative">
              <div class="arrow-slick arrow-left col-2 prev-event2" >
                <i class="fa-solid fa-chevron-left"></i>
              </div>

              <ngx-slick-carousel class="carousel intro-carrousel col-8"  [config]="{  prevArrow: '.prev-event2',  nextArrow: '.next-event2', draggable: true, dots: false, infinite: true, arrows: true}">
                <div ngxSlickItem *ngFor="let flash of flashs" class="slide text-center">
                  <div class="bib-container">
                    <p class="my-3"><small>{{flash.nom}}</small></p>
                    <img class="img-carousel" src="{{flash.vignette}}">
                    <button class="btn btn-dark my-4"> <a href="{{flash.fichier}}" target="_blank">TÉLÉCHARGER LE FLASH INFO</a></button>
                  </div>
                </div>
              </ngx-slick-carousel>

              <div class="arrow-slick arrow-right col-2 next-event2" >
                <i class="fa-solid fa-chevron-right"></i>
              </div>
            </div>
            <div class="text-center">
              <a href="/ma-commune/publications/flash-info">consulter les numéros précédents</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-12 form">
          <div class="point"></div>
          <div id="inscriptions">
            <h3>Inscrivez-vous au Flash Info</h3>
            <p>Vous souhaitez recevoir les dernières informations de la Ville ? Inscrivez-vous aux newsletters</p>

            <form [formGroup]="newsletterForm" (ngSubmit)="onSubmit()">
              <div class="form-group">
                <label for="lastname">Votre nom</label>
                <input type="text" formControlName="nom" class="form-control" id="lastname" placeholder="ex. Dupont" >
              </div>
              <div class="form-group my-2">
                <label for="firstname">Votre prénom</label>
                <input type="text" formControlName="prenom" class="form-control" id="firstname"   placeholder="ex. Martin">
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Votre email </label>
                <input type="email" formControlName="mail" class="form-control" id="exampleInputEmail1"  placeholder="ex. monemail@mail.com">
              </div>
              <div class="form-check my-3">
                <input type="checkbox" formControlName="terms" class="form-check-input" id="check">
                <label class="form-check-label" for="check">J'accepte les <a class="link-terms" routerLink="mentions-legales"> termes et conditions </a></label>
              </div>
              <div class="text-center">
                <button type="submit" class="btn btn-dark">S'INSCRIRE</button>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
</section>

<section id="images">
  <div class="container images">
    <div class="d-flex justify-content-between align-items-end">
        <h2>Brécé <span>EN IMAGES</span></h2>
        <img class="ml-auto img-fluid float-end" src="assets/icons/element-graphique-point-jaune.svg">
    </div>
    <div class="points-relative">
      <img class="ml-auto img-fluid float-end" src="assets/icons/element-graphique-point.svg">
    </div>
  </div>
</section>
<div class="container">
  <ngx-masonry [options]="{ gutter: 10 }" [ordered]="true">
    <div ngxMasonryItem class="masonry-item" *ngFor="let img of masonryItems" (click)="openSlider(img)">
      <img src="{{img.fileName}}" alt="image" />
    </div>
  </ngx-masonry>
</div>
