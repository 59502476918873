<div class="modal-header">
    <h1 class="modal-title">{{title}}</h1>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>

<div class="modal-body">
    <form [formGroup]="propositionForm" (ngSubmit)="onSubmit()" class="row g-3">

        <div class="col-md-6">
          <label for="floatingNom">Nom</label>
          <input type="text" class="form-control nom" id="floatingNom" formControlName="nom" placeholder="Votre nom">
        </div>

        <div class="col-md-6">
          <label for="floatingPrenom">Prénom</label>
          <input type="text" class="form-control prenom" id="floatingPrenom" formControlName="prenom" placeholder="votre prénom">
        </div>

        <div class="col-8">
          <label for="floatingMail">E-mail</label>
          <input type="text" class="form-control mail" id="floatingMail" formControlName="mail" placeholder="Votre e-mail">
        </div>

        <div class="col-4">
          <label for="floatingPhone">Téléphone</label>
          <input type="text" class="form-control phone" id="floatingPhone" formControlName="tel" placeholder="Votre téléphone">
        </div>

        <div class="col-12">
          <label for="floatingTexte">Description de l'évènement</label>
          <textarea type="text" class="form-control message" id="floatingTexte" formControlName="description" placeholder="Dites-nous en plus"></textarea>
        </div>

        <div class="col-12">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="gridCheck">
            <label class="form-check-label" for="gridCheck">
              En soumettant ce formulaire, j’accepte que les informations saisies dans ce formulaire soient utilisées, exploitées, traitées pour permettre de me recontacter, pour m’envoyer des informations, dans le cadre de la relation commerciale qui découle de cette demande. <a class="link" href="/confidentialite">* En savoir plus. </a>
            </label>
          </div>
        </div>

        <div class="col-12">
          <button type="submit" class="btn btn-primary">Envoyé</button>
        </div>
    </form>
</div>
