import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { CommonService } from 'src/app/_services/common.service';
import { MenuService } from 'src/app/_services/menu.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-enfance-et-jeunesse',
  templateUrl: './enfance-et-jeunesse.component.html',
  styleUrls: ['./enfance-et-jeunesse.component.scss']
})
export class EnfanceEtJeunesseComponent implements OnInit {

  rubrique: any;
  menu: any;
  children: any;
  constructor(private commonService: CommonService, private titleService: Title, private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService,
    private route: ActivatedRoute, private menuService: MenuService) { }
  ngOnInit(): void {
    this.route.params.subscribe( params =>
      {
        this.rubrique = params["rubrique"];
        this.getChildren();
      });
  }

  getChildren() {
    this.menuService.getChildren(this.rubrique).subscribe(data => {
      this.children = data[0]['items'];
      this.commonService.changeData("");
      this.titleService.setTitle(data[0].nom);
      this.ngDynamicBreadcrumbService.updateBreadcrumbLabels({customMenu: "Mon quotidien", customSection: data[0].parent, customTitle: data[0].nom});
    })
  }
}
