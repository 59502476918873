import {AfterContentChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";
import { MenuService } from 'src/app/_services/menu.service';
import {CommonService} from "../../_services/common.service";

@Component({
  selector: 'app-website',
  templateUrl: './website.component.html',
  styleUrls: ['./website.component.scss']
})
export class WebsiteComponent implements OnInit, AfterContentChecked {

  text = '';
  image = '';
  checked = false;
  menu: any;

  constructor(public titleService: Title, private commonService: CommonService, private changeDetector: ChangeDetectorRef,
    private menuService: MenuService
  ) { }

  ngOnInit(): void {
    this.menuService.getMenus().subscribe(data => {
      this.menu = data[0].items;
    });
    this.commonService.data$.subscribe(res => {
        this.text = res.title
        this.image = res.image
      }
    )
  }
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  showToggle() {
    this.checked = !this.checked
  }
}
